<template>
  <div class="classics-float-new">
    <!-- <img src="../../assets/images/classics/decoration2.png" class="tips" alt="" /> -->
    <div class="btnBox">
      <div
        class="btn-item"
        @click="currentChange(index)"
        :class="current == index ? 'ac-item' : ''"
        v-for="(item, index) in tabList"
        :key="index">
        <div>
          <span v-for="text in item.length" :key="text">{{
            item[text - 1]
          }}</span>
        </div>
      </div>
    </div>
    <!-- <img src="../../assets/images/classics/decoration3.png" class="tips tips-b" alt="" /> -->
  </div>
</template>

<script>
export default {
  props: {
    tabList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      current: 0,
    };
  },
  created() {
    if (["0", "1", "2", "3", "4"].includes(this.$route.query.activeTab)) {
      this.current = this.$route.query.activeTab;
      this.$emit("currentChange", this.current);
    }
  },
  methods: {
    currentChange(i) {
      this.current = i;
      this.$emit("currentChange", this.current);
    },
  },
};
</script>

<style lang="scss">
.classics-float-new {
  position: absolute;
  width: 0.4rem;
  // height: 5.51rem;
  top:3.5rem;
  left: 1.55rem;
  // background: url(../../assets/images/classics/decoration-new.png) no-repeat;
  background-size: 100% 100%;
  border-left: 2px solid rgba(34, 17, 79, 1);
  display: flex;
  flex-direction: column;
  // overflow: hidden;
  z-index: 99;
  padding-bottom: 1rem;
  padding-left: 0.06rem;
  // background: red;

  .tips {
    width: 0.15rem;
    margin-left: 0.2rem;
    margin-top: 0.15rem;
  }

  .tips-b {
    margin-top: 0;
    margin-bottom: 0.15rem;
  }

  .btnBox {
    // margin-left: 0.1rem;
    // padding-top: 0.23rem;
    // padding-left: 0.03rem;
    .btn-item {
      width: 0.34rem;
      height: 1.29rem;
      text-align: center;
      background: url(../../assets/images/classics/left-btn-new.png) no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.15rem;
      cursor: pointer;

      div {
        width: 0.12rem;
        // padding: 0.5rem 0;
        display: flex;
        flex-direction: column;
      }
    }

    .ac-item {
      background: url(../../assets/images/classics/left-status-btn-new.png)
        no-repeat;
      background-size: 100% 100%;
    }
  }
}
.classics-float-new::before {
  content: "";
  position: absolute;
  top: -0.3rem;
  left: -0.1rem;
  width: 0.45rem;
  height: 0.32rem;
  background: url(../../assets/images/classics/decoration-new2.png) no-repeat;
  background-size: 100% 100%;
  z-index: -1;
}
.classics-float-new::after {
  content: "";
  position: absolute;
  bottom: -0.4rem;
  left: 0.08rem;
  width: 0.29rem;
  height: 2.44rem;
  background: url(../../assets/images/classics/decoration-new3.png) no-repeat;
  background-size: 100% 100%;
  z-index: -1;
}
</style>
