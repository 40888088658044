<template>
  <div id="Roll">
    <leftFloat :tabList="tabList" @currentChange="currentChange" />
    <div class="Roll_box">
      <div class="typebox" v-show="this.current == 1">
        <div class="selectbox">
          <div class="select-box point" @click="istype = !istype">
            <div class="input-value flex-s">
              {{ parseSelectList.find((item) => item.value == value)?.label }}
            </div>
            <div class="row">
              <img
                src="../../assets/images/NewUser/pc-newuser-pullicon.png"
                alt=""
              />
            </div>
            <div v-show="istype" class="float-box">
              <div
                @click="changeSelect(item)"
                class="float-box-item flex-s"
                v-for="(item, index) in parseSelectList"
                :key="index"
              >
                {{ item.label }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="topbox">
        <rollItem
          class="item"
          v-for="item in rightList"
          :key="item?.id"
          :item="item"
          type="1"
          @itemClick="toRoom"
        ></rollItem>
        <rollItem
          class="item"
          v-for="item in leftList"
          :key="item?.id"
          :item="item"
          @itemClick="toRoom"
        >
        </rollItem>
      </div>
    </div>
  </div>
</template>

<script>
import { Rooms, UserRooms, rollPending } from "@/network/api.js";
import { tabList } from "./index.js";
import leftFloat from "@/components/left-float/new.vue";
import rollItem from "./components/rollnewitem.vue";
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  name: "Roll",
  data() {
    return {
      RoomList: [], //房间列表
      Status: "", //房间状态
      current: 0,
      tabList,
      searchroll: "", //搜索roll房
      //分页
      per_page: 0,
      PageNum: 0,
      userrolltype: 1,

      selectList: [
        {
          value: 1,
          label: "进行中",
        },
        {
          value: 2,
          label: "已完成",
        },
      ],
      value: 1,
      istype: false,
    };
  },
  components: {
    leftFloat,
    rollItem,
  },

  computed: {
    ...mapState(["user", "RegisterShow", "SignInShow"]),
    leftList() {
      // 主播
      if (this.RoomList.length > 0) {
        // return this.RoomList.filter((_, index) => index < 4);
        return this.RoomList.map((v) => {
          if (v.type == 1) {
            return v;
          }
        });
      }
    },
    rightList() {
      // 官方
      if (this.RoomList.length > 0) {
        // return this.RoomList.filter((_, index) => index > 4);
        return this.RoomList.map((v) => {
          if (v.type == 0) {
            return v;
          }
        });
      }
    },
    parseSelectList() {
      const array = this.selectList.map((item) => {
        return {
          label: item?.label || item,
          value: item?.value || item,
        };
      });
      return array;
    },
  },

  created() {
    this.GetRooms(1);
  },
  methods: {
    changeSelect(v) {
      this.value = v.value;
      this.userrolltype = v.value;
      this.GetUserRooms();
    },

    ...mapMutations(["User", "Leave", "GetRegisterShow", "GetSignInShow"]),
    currentChange(i) {
      this.current = i;
      this.ChangeRolls(i + 1);
    },
    //获取房间列表(初始)
    GetRooms() {
      Rooms().then((res) => {
        this.per_page = res.data.data.per_page;
        this.PageNum = res.data.data.total;
        this.RoomList = res.data.data.data;
      });
    },

    //参加房间记录(我参与的)
    GetUserRooms() {
      // if (!this.user.id) {
      //   this.$message({
      //     message: "未登录，请登录后操作",
      //     type: "error",
      //   });
      //   this.PostSignInShow();
      // } else {
      let params = {
        page: 1,
        type: this.userrolltype,
      };
      UserRooms(params).then((res) => {
        this.per_page = res.data.data.per_page;
        this.PageNum = res.data.data.total;
        this.RoomList = res.data.data.data;
      });
      // }
    },
    //登录
    PostSignInShow() {
      this.GetSignInShow(true);
    },
    // handleCurrentChange(val) {
    //   this.GetRooms(val);
    //   this.GetUserRooms(val);
    // },
    //改变房间列表
    ChangeRolls(v) {
      this.Status = String(v);
      switch (v) {
        case 1:
          this.GetRooms();
          this.userrolltype = 1;
          break;
        case 2:
          if (!this.user.id) {
            this.$message({
              message: "未登录，请登录后操作",
              type: "error",
            });
            this.PostSignInShow();
          } else {
            this.GetUserRooms();
          }
          break;
      }
    },

    //路由跳转房间详情
    toRoom(id) {
      this.$router.push({
        path: "/room",
        query: {
          id: id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.Roll_box {
  width: 16rem;
  min-height: 50vh;
  margin: 0 auto 1rem;
  .typebox {
    padding-left: 0.7rem;
    height: 0.7rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 0.2rem;
    .selectbox {
      border: 0.01rem solid #2f2f5e;
      .select-box {
        width: 2.04rem;
        height: 0.4rem;
        background: #08080f;

        position: relative;
        .input-value {
          width: 100%;
          height: 100%;
          line-height: 0.4rem;
          padding-left: 0.2rem;
          box-sizing: border-box;
          font-size: 0.16rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #c5bbed;
          overflow: hidden;
          white-space: nowrap;
        }
        .row {
          position: absolute;
          top: 0.1rem;
          right: 0.2rem;
          z-index: 1;
          img {
            width: 0.26rem;
            height: 0.24rem;
          }
        }

        .float-box {
          position: absolute;
          left: -1px;
          background: #08080f;
          z-index: 99;
          border: 0.01rem solid #2f2f5e;
          border-top: 0;
          .float-box-item {
            width: 2.04rem;
            height: 0.34rem;
            line-height: 0.34rem;
            padding: 0 0.2rem;
            box-sizing: border-box;
            font-size: 0.16rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #c5bbed;
            white-space: nowrap;
            overflow: hidden;
          }
          .float-box-item:hover {
            background: #2f2f5e;
            color: #c5bbed;
          }
        }
      }
    }
  }
  .topbox {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-left: 0.7rem;
    .item {
      margin: 0.1rem 0.08rem;
    }
  }
}
</style>
